import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { colors } from "../../constants/Colors"
import { REGISTRAZIONE_POPUP } from "../../constants/popups"
import { openErrorToast, openInfoToast } from "../../redux/toastSlice"
import AuthIcons from "../Atoms/AuthIcons"
import { CustomButtonInput } from "../Atoms/Button"
import PrivacyNotice from "../Atoms/PrivacyNotice"
import CheckBox from "../CheckBox/CheckBox"
import {
  Link,
  CheckBoxContainer,
  Form,
  Inner,
  Input,
  LinkNo,
  LinksContainer,
} from "./AuthModal.styled"
import { loginUserAsync, registerUserAsync } from "../../redux/userSlice"

const RegisterForm = ({ close, setState }) => {
  const [checked, setChecked] = useState(0)
  const { register, handleSubmit } = useForm()

  const dispatch = useDispatch()

  const onSubmit = data => {
    if (data.password != data.repassword) {
      dispatch(openErrorToast("Le password non corrispondono"))
      return
    }
    close()
    dispatch(
      registerUserAsync(data, () =>
        dispatch(openInfoToast(REGISTRAZIONE_POPUP))
      )
    )
  }

  return (
    <Inner>
      <LinksContainer>
        <Link onClick={() => setState(0)} active={false}>
          Accedi
        </Link>
        <LinkNo> / </LinkNo>
        <Link onClick={() => setState(1)} active={true}>
          Registrati
        </Link>
      </LinksContainer>
      <AuthIcons isLogin={false} close={close} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input required placeholder={"Nome"} {...register("nome")} />
        <Input required placeholder={"Cognome"} {...register("cognome")} />
        <Input required placeholder={"Città"} {...register("citta")} />
        <Input required placeholder={"Email"} {...register("email")} />
        <Input
          required
          placeholder={"Crea password"}
          type={"password"}
          {...register("password")}
        />
        <Input
          required
          placeholder={"Ripeti password"}
          type={"password"}
          {...register("repassword")}
        />
        <div style={{ height: 20 }} />
        <CheckBoxContainer>
          <CheckBox onClick={() => setChecked(0)}>
            <p style={{ margin: 0, marginTop: -3 }}>Uomo</p>
            <CheckBox.Input
              type="checkbox"
              checked={checked == 0 ? "checked" : false}
            />
            <CheckBox.CheckRound></CheckBox.CheckRound>
          </CheckBox>
          <CheckBox onClick={() => setChecked(1)}>
            <p style={{ margin: 0, marginTop: -3 }}>Donna</p>
            <CheckBox.Input
              type="checkbox"
              checked={checked == 1 ? "checked" : false}
            />
            <CheckBox.CheckRound></CheckBox.CheckRound>
          </CheckBox>
        </CheckBoxContainer>
        <div style={{ height: 20 }} />
        <CustomButtonInput
          color={colors.blue}
          value="REGISTRATI"
          type="submit"
        />
        <PrivacyNotice />
      </Form>
    </Inner>
  )
}

export default RegisterForm
