import React from "react"
import logo from "../images/logo.png"
import W3Bar from "../components/W3Bar/W3Bar"
import AuthModal from "../components/AuthModal/AuthModal"
import { useSelector } from "react-redux"
import { selectChirurgia, selectMedicina, selectPosition } from "../redux/menuSlice"
import { navigate } from "gatsby"
import useWindowDimensions from "../hooks/useWindowDimensions"
import GlobalIcon from "../components/Atoms/GlobalIcon"
import navigateToTrattamento from "../functions/navigateToTrattamento"
import { selectCurrentUser } from "../redux/userSlice"
import getClosestCity from "../functions/getClosestCity"
import { selectLayoutComuni } from "../redux/layoutSlice"
const DropDownChiru = () => {
  const chirurgia = useSelector(selectChirurgia)
  if (!chirurgia.categorie) {
    return null
  }
  return (
    <W3Bar.DropContentWrapper>
      <W3Bar.DropContent>
        <W3Bar.Header>
          {chirurgia.categorie.map((item, i) => (
            <W3Bar.HeaderTitle
              key={i + "chiru"}
              style={{
                flex: chirurgia.trattamenti[item.id_sys].length > 8 ? 8 : 4,
              }}
            >
              {item.categoria}
            </W3Bar.HeaderTitle>
          ))}
          <W3Bar.HeaderSpace />
        </W3Bar.Header>
        <W3Bar.Header style={{ backgroundColor: "white" }}>
          {chirurgia.categorie.map((cat, i) => (
            <W3Bar.HeaderContent
              key={i + "chsiru"}
              style={{
                borderLeftColor: i == 0 ? "white" : "#efefef",
                flex: chirurgia.trattamenti[cat.id_sys].length > 8 ? 8 : 4,
              }}
            >
              {chirurgia.trattamenti[cat.id_sys].map(item => (
                <W3Bar.HeaderItem onClick={() => navigateToTrattamento(item)}>
                  {item.trattamento}
                </W3Bar.HeaderItem>
              ))}
            </W3Bar.HeaderContent>
          ))}
          <W3Bar.HeaderSpace />
        </W3Bar.Header>
      </W3Bar.DropContent>
    </W3Bar.DropContentWrapper>
  )
}

const DropDownMed = () => {
  const medicina = useSelector(selectMedicina)

  if (!medicina.categorie) {
    return null
  }
  return (
    <W3Bar.DropContentWrapper>
      <W3Bar.DropContent>
        <W3Bar.Header>

          {medicina.categorie.map((item, i) => (
            <W3Bar.HeaderTitle
              style={{
                flex: medicina.trattamenti[item.id_sys].length > 8 ? 8 : 3,
              }}
            >
              {item.categoria}
            </W3Bar.HeaderTitle>
          ))}
          <W3Bar.HeaderSpace />
        </W3Bar.Header>
        <W3Bar.Header style={{ backgroundColor: "white" }}>

          {medicina.categorie.map((cat, i) => (
            <W3Bar.HeaderContent
              style={{
                borderLeftColor: i == 0 ? "white" : "#efefef",
                flex: medicina.trattamenti[cat.id_sys].length > 8 ? 8 : 3,
              }}
            >
              {medicina.trattamenti[cat.id_sys].map(item => (
                <W3Bar.HeaderItem onClick={() => navigateToTrattamento(item)}>
                  {item.trattamento}
                </W3Bar.HeaderItem>
              ))}
            </W3Bar.HeaderContent>
          ))}
          <W3Bar.HeaderSpace />
        </W3Bar.Header>
      </W3Bar.DropContent>
    </W3Bar.DropContentWrapper>
  )
}

const W3barContainer = () => {
  const position = useSelector(selectPosition)
  const comuni = useSelector(selectLayoutComuni)
  const user = useSelector(selectCurrentUser)
  const [modal, setModal] = React.useState(false)
  const parentRef = React.useRef()
  const { width } = useWindowDimensions()
  return (
    <W3Bar>
      {modal && (
        <AuthModal
          parentRef={parentRef}
          open={modal}
          close={() => setModal(false)}
        />
      )}
      <W3Bar.LogoLink to={"/"}>
        <img height="60px" src={logo} />
      </W3Bar.LogoLink>
      <div style={{ flex: 1, justifyContent: "flex-start", marginTop: 10 }}>
        <W3Bar.Dropdown>
          <W3Bar.Button to="/chirurgia-estetica">
            Chirurgia estetica
            <W3Bar.LinkCaret
              height="6"
              role="img"
              viewBox="0 0 10 6"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L5.07223 5.1517L9.23083 1"></path>
            </W3Bar.LinkCaret>
          </W3Bar.Button>
          <DropDownChiru />
        </W3Bar.Dropdown>
        <W3Bar.Dropdown>
          <W3Bar.Button to="/medicina-estetica">
            Medicina estetica
            <W3Bar.LinkCaret
              height="6"
              role="img"
              viewBox="0 0 10 6"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L5.07223 5.1517L9.23083 1"></path>
            </W3Bar.LinkCaret>
          </W3Bar.Button>
          <DropDownMed />
        </W3Bar.Dropdown>
        <W3Bar.Item to={`/chirurghi-estetici/${getClosestCity(position,comuni)}`}>Chirurghi</W3Bar.Item>
        <W3Bar.Item to={"/esperienze"}>Esperienze</W3Bar.Item>
        <W3Bar.Item to={"/articoli"}>Articoli</W3Bar.Item>
        {/*   <W3Bar.Item to={"/videoconsulto"}>Videoconsulto</W3Bar.Item>*/}
      </div>
      {width > 1225 ? (
        <div style={{ marginRight: "20px", marginTop: 12 }}>
          {user ? (
            <W3Bar.ItemDiv to={"/profilo"} style={{ fontSize: 14 }}>
              Profilo
            </W3Bar.ItemDiv>
          ) : (
            <W3Bar.ItemDiv
              to={"#"}
              style={{ fontSize: 14 }}
              onClick={() => setModal(true)}
            >
              Accedi/ Registrati
            </W3Bar.ItemDiv>
          )}
          <W3Bar.Item style={{ fontSize: 14 }} to={"https://backend.chirurghiestetici.it/admin_chirurgo/admin_chirurgo_login.aspx"}>
            Area Chirurghi
          </W3Bar.Item>
        </div>
      ) : (
        <div style={{ marginRight: "20px", marginTop: 12 }}>
          <W3Bar.ItemDiv
            to={"#"}
            style={{ fontSize: 14, marginTop: -10 }}
            onClick={() => (user ? navigate("/profilo") : setModal(true))}
          >
            <GlobalIcon
              style={{ height: 25 }}
              img={require("../images/mobilebar/User.png")}
            />
          </W3Bar.ItemDiv>
          <W3Bar.Item
            style={{ fontSize: 14, marginTop: -10 }}
            to={"/chirurgo_login"}
          >
            <GlobalIcon
              style={{ height: 25 }}
              img={require("../images/doctor_icon.png")}
            />
          </W3Bar.Item>
        </div>
      )}
    </W3Bar>
  )
}

export default W3barContainer
