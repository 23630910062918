import React from "react"
import {
  Container,
  Item,
  Dropdown,
  DropContentWrapper,
  DropContent,
  DropButton,
  Header,
  LogoLink,
  LinkCaret,
  HeaderItem,
  HeaderContent,
  HeaderTitle,
  HeaderSpace,
  ItemDiv,
} from "./styles/W3"

const W3Bar = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

W3Bar.LogoLink = function W3BarLogoLink({ children, ...restProps }) {
  return <LogoLink {...restProps}>{children}</LogoLink>
}

W3Bar.Item = function W3BarItem({ children, ...restProps }) {
  return <Item {...restProps}>{children}</Item>
}

W3Bar.ItemDiv = function W3BarItemDiv({ children, ...restProps }) {
  return <ItemDiv {...restProps}>{children}</ItemDiv>
}

W3Bar.Dropdown = function W3BarDropdown({ children, ...restProps }) {
  return <Dropdown {...restProps}>{children}</Dropdown>
}

W3Bar.DropContentWrapper = function W3DropContentWrapper({
  children,
  ...restProps
}) {
  return <DropContentWrapper {...restProps}>{children}</DropContentWrapper>
}

W3Bar.DropContent = function W3DropContent({ children, ...restProps }) {
  return <DropContent {...restProps}>{children}</DropContent>
}

W3Bar.Header = function W3DropHeader({ children, ...restProps }) {
  return <Header {...restProps}>{children}</Header>
}

W3Bar.HeaderTitle = function W3HeaderTitle({ children, ...restProps }) {
  return <HeaderTitle {...restProps}>{children}</HeaderTitle>
}

W3Bar.HeaderItem = function W3HeaderItem({ children, ...restProps }) {
  return <HeaderItem {...restProps}>{children}</HeaderItem>
}
W3Bar.HeaderContent = function W3HeaderContent({ children, ...restProps }) {
  return <HeaderContent {...restProps}>{children}</HeaderContent>
}

W3Bar.HeaderSpace = function W3HeaderSpace({ children, ...restProps }) {
  return <HeaderSpace {...restProps}>{children}</HeaderSpace>
}

W3Bar.Button = function W3BarButton({ children, ...restProps }) {
  return <DropButton {...restProps}>{children}</DropButton>
}

W3Bar.LinkCaret = function W3BarLinkCaret({ children, ...restProps }) {
  return <LinkCaret {...restProps}>{children}</LinkCaret>
}

export default W3Bar
