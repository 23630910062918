import React from "react"
import styled from "styled-components"

export const MobileFormInputStyle = styled.input`
  box-shadow: 5px 5px 20px #efefef;
  -webkit-appearance: none;
  border: ${props => (props.error ? "1px solid red" : "none")};
  padding: 15px;
  width: 100%;
  border-radius: 12px;
  ::placeholder {
    color: ${({ populated }) => (populated ? "black" : "#ccd0d4")};
    font-style: ${({ populated }) => (populated ? "normal" : "italic")};
    opacity: 1;
  }
`

export default function MobileFormInput({ ...restProps }) {
  return <MobileFormInputStyle {...restProps} />
}
