export const colors = {
  MID_BLUE: "#264a79",
  ACCENT_BLUE: "#5492e3",
  LIGHT_BLUE: "#337AB7",
  OCEAN_BLUE: "#7AA5CC",
  LIGHT_OCEAN: "#EFF5FA",
  LIGHT_OCEAN_2: "#FEFEFE",
  LIGHT_OCEAN_3: "#E1EAF2",
  DARK_BLUE: "#0E4D83",
  BLUE: "#264A79",
  DOUBLE_DARK_BLUE: "#052957",
  BLACK_BLUE: "#334154",
  LIGHT_BLACK: "#66717F",
  ERROR: "#F7632A",
  YELLOW: "#FFCF5C",
  WHITE_BLUE: "#E9F0F7",
  LIGHT_GRAY_01: "#CCD0D4",
  LIGHT_GRAY_02: "#797979",
  LIGHT_GRAY_03: "#EFEFEF",
  GRAY_01: "#99A0A9",
  GRAY_02: "#A6A7A8",
  BLACK: "#001229",
  RED: "#E22E03",
  BORDER: "#E3E3E3",
}
