import { Link } from "gatsby"
import styled from "styled-components"
import zIndexes from "../../../constants/zIndexes"

export const Wrapper = styled.div`
  @media (min-width: 981px) {
    display: none;
  }
`
export const Container = styled.div`
  position: fixed;
  background-color: #fafafa;
  box-shadow: 0px 3px 6px #00000012;
  height: 80px;
  width: 100%;
  align-content: center;
  z-index: ${zIndexes.top_bar};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-top: 15px;
`

export const LogoLink = styled(Link)`
  margin-right: -35px;
`

export const RightButtons = styled.div`
  flex-direction: row;
  justify-content: center;
`

export const SearchImg = styled.img`
  filter: invert(0.1);
  width: 25px;
  height: 25px;
  margin: 10px;
`

export const Image = styled.img`
  width: 25px;
  height: 25px;
  margin: 10px;
`

export const Images = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:first-child ${Image} {
    margin-left: -10px;
  }
`

export const SearchWrapper = styled.div`
  width: 100%;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndexes.dropdown_menu};
  transition: transform 0.3s ease-in-out;
  transform: ${({ searchOpen }) =>
    searchOpen ? "translateY(78px)" : "translateY(-280px)"};
  background-color: #fafafa;
`

export const BaseButton = styled.div`
  padding: 10px;
  font-weight: 700;
  text-align: center;
  font-size: 12px;
  border-radius: 8px;
`

export const ButtonOutline = styled(BaseButton)`
  background-color: white;
  border-style: solid;
  border-color: #337ab7;
  border-width: 0.1em;
  margin-bottom: 10px;
  color: #172d4a;
`

export const ButtonLight = styled(BaseButton)`
  background-color: #337ab7;
  border-color: #337ab7;
  color: white;
`

export const ProfileTitle = styled.p`
  font-size: 13px;
  font-weight: 900;
  text-align: center;
  margin-top: 30px;
  color: #172d4a;
`

export const ButtonDark = styled(BaseButton)`
  background-color: #172d4a;
  border-color: #172d4a;
  color: white;
`

export const ProfileWrapper = styled(SearchWrapper)`
  display: flex;
  flex-direction: column;
  padding-left: 25%;
  padding-right: 25%;
  z-index: ${zIndexes.dropdown_menu};
`

export const SearchBarWrapper = styled.div`
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-right: 10px;
  box-shadow: 5px 5px 20px #0260d91a;
  margin-left: 10px;
`

export const SearchBar = styled.input`
  outline: none;
  border: none;
  background-color: white;

  padding: 10px;
`
