import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import { device } from "../../../constants/breakpoints"

export const Container = styled.div`
  font-family: "Poppins", sans-serif;
`

export const FeatureWrapper = styled.div`
  display: flex;
  background: transparent;
  justify-content: center;
`
export const FeatureText = styled.h2`
  width: 30%;
  color: white !important;
  font-size: 22px;
  font-weight: 700;

  @media ${device.tablet} {
    width: 60%;
    font-size: 24px;
    text-align: center;
  }
`
export const FeatureImage = styled.a`
  margin: 10px;
`

export const Feature = styled.div`
  display: flex;

  flex-direction: row;
  width: 650px;
  height: 100px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 122, 183, 0.29);

  @media (min-width: 769px) {
    position: absolute;
    margin-top: -100px;
  }
  @media ${device.tablet} {
    flex-direction: column;
    height: 250px;
  }
`
export const ItemsContainer = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    max-height: ${props => (props.open ? "800px" : "0")};
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    &[title~="expanded"] {
      max-height: 800px;
    }
  }
`
export const ContentContainer = styled.div`
  padding: 20px 60px;
  background: #001229;

  @media ${device.tablet} {
    padding: 20px 20px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
`

export const Title = styled.h6`
  color: white !important;

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    font-size: 1rem;

    padding-left: 0;
    font-weight: bold;
    color: #264a79;
    text-decoration: none;
    transition: color 0.1s linear;

    &[title~="articoli"] {
      display: none;
    }

    &[title~="dropdown"] {
      color: red;
      margin-bottom: 5px;
      margin-top: 10px !important;
    }

    &[title~="dropdown"]::after {
      margin-top: 5px;
      margin-left: 10px;
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: "";
      display: inline-block;
      height: 0.45em;
      left: 0.15em;
      position: relative;
      z-index: 8;
      top: 0.15em;
      transform: ${({ open }) => (!open ? "rotate(135deg)" : "rotate(-45deg)")};
      vertical-align: top;
      width: 0.45em;
    }
  }
`
export const Spacer = styled.h6`
  color: white !important;
  margin-top: 39px;
  @media ${device.tablet} {
    display: none;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  @media ${device.tablet} {
    &[title~="bottom"] {
      margin-bottom: -20px;
    }
  }

  & ${Title} {
    margin-top: 20px;
  }
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 0px;

    ${Column}:nth-of-type(1) {
      order: 2;
    }

    ${Column}:nth-of-type(2) {
      order: 1;
    }

    ${Column}:nth-of-type(4) {
      order: 3;
    }

    ${Column}:nth-of-type(3) {
      order: 3;
    }

    ${Column}:nth-of-type(4) {
      order: 2;
    }
  }
`

export const Link = styled.div`
  color: #9d9d9d;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 5px;
  &:hover {
    color: #fff;
    transition: 200ms ease-in;
  }
`

export const Bottom = styled.div`
  border-top-color: #9d9d9d;
  padding-top: 10px;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-top: 20px;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const BottomLinks = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  align-content: center;
`

export const BottomIcons = styled.div`
  display: flex;
  flex-direction: row;

  align-content: center;
`

export const BottomLink = styled(GatsbyLink)`
  color: white;
  text-decoration: none;
`

export const BottomIcon = styled.a`
  margin-left: 15px;
  :first-of-type {
    margin-left: 0;
  }
  cursor: pointer;
  &:hover > img {
    tint-color: red;
  }

  @media ${device.tablet} {
    margin-top: 10px;
    padding-bottom: 20px;
  }
`
