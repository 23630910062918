import React, { useState } from "react"
import { MenuItems } from "../../constants/MenuItems"
import {
  StyledMenu,
  MenuItem,
  DropDownItem,
  DropDownContent,
  DropDownContainer,
  Icons,
  Icon,
  Title,
  StyledMenuWrapper,
} from "./styles/Menu.styled"

const Menu = ({ open }) => {
  const [openAcc, setOpenAcc] = useState(false)
  return (
    <StyledMenu open={open}>
      <MenuItem to="/">Home</MenuItem>
      {MenuItems.map(item =>
        false ? (
          <DropDownContainer
            open={openAcc}
            onClick={() => setOpenAcc(!openAcc)}
          >
            <DropDownItem open={openAcc}>{item.title}</DropDownItem>
            <DropDownContent open={openAcc}>
              {item.subItems.map(item => (
                <MenuItem>{item.title}</MenuItem>
              ))}
            </DropDownContent>
          </DropDownContainer>
        ) : (
          <MenuItem to={item.href}>{item.title}</MenuItem>
        )
      )}
      <MenuItem to="/perche_sceglierci" style={{ marginTop: 35 }}>
        Perchè sceglierci
      </MenuItem>
      <MenuItem to="/404" style={{ border: "none" }}>
        Contatti
      </MenuItem>
      <Title style={{ border: "none", marginTop: 20 }}>Seguici</Title>
      <Icons>
        <Icon src={require("../../images/mobilebar/facebook.png")} />
        <Icon src={require("../../images/mobilebar/instagram.png")} />
        <Icon src={require("../../images/mobilebar/twitter.png")} />
      </Icons>
      <Title style={{ border: "none", marginTop: 10 }}>
        Scarica la nostra app
      </Title>
      <Icons>
        <img
          width="120px"
          height="40px"
          style={{ marginRight: 20 }}
          src={require("../../images/mobilebar/app-store.png")}
        />
        <img
          width="120px"
          height="40px"
          src={require("../../images/mobilebar/g-play.png")}
        />
      </Icons>
    </StyledMenu>
  )
}
export default Menu
