// Menu.styled.js
import { Link } from "gatsby"
import styled from "styled-components"

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  z-index: 9;
  background-image: linear-gradient(-30deg, black -40%, white 0%, #c1dde6 120%);
  height: 100vh;
  text-align: left;
  padding: 50px;
  padding-top: 3.5rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  width: 100%;
  padding-bottom: 150px;
`

export const DropDownContainer = styled.div`
  border-bottom-style: solid;
  border-bottom-color: ${props => (props.open ? "transparent" : "#264a79")};
  transition: all 0.1s ease-in-out;
  border-bottom-width: 1px;
  padding-left: 0;
`

export const DropDownContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  margin-bottom: ${props => (props.open ? "30px" : "10px")};
  max-height: ${props => (props.open ? "800px" : "0")};
  transition: all 0.4s ease-in-out;
  overflow: hidden;
`

export const DropDownItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1rem;

  padding-top: 1rem;
  padding-left: 0;
  font-weight: bold;
  color: #264a79;
  text-decoration: none;
  transition: color 0.3s linear;

  &::after {
    margin-top: 8px;
    margin-left: 15px;
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: "";
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;

    top: 0.15em;
    transform: ${({ open }) => (!open ? "rotate(135deg)" : "rotate(-45deg)")};
    vertical-align: top;
    width: 0.45em;
  }
`

export const MenuItem = styled(Link)`
  font-size: 1rem;
  padding: 1rem;
  padding-left: 0;
  font-weight: bold;
  color: #264a79;
  text-decoration: none;
  transition: color 0.3s linear;
  border-bottom-style: solid;
  border-bottom-color: #264a79;
  border-bottom-width: 1px;

  &:hover {
    color: #337ab7 !important;
  }
`
export const Title = styled.span`
  font-weight: bold;
  color: #264a79;
  padding: 1rem;
  padding-left: 0;
`

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0.1rem;
`
export const IconsStore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.1rem;
`

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`
