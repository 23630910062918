import React from "react"
import styled from "styled-components"

const Privacy = styled.p`
  text-align: center;
  margin-top: 10px;
  font-size: 11px;
  color: #a6a7a8;
  & > a {
    color: ${({ theme }) => theme.blue};
    text-decoration: none;
  }
`

export default function PrivacyNotice() {
  return (
    <Privacy>
      Continuando accetti i
      <a href={"#"}>
        <span> termini </span>
      </a>
      e
      <a href={"#"}>
        <span> condizioni sulla privacy</span>
      </a>
    </Privacy>
  )
}
