import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  margin-top: -5px;
  width: 25px;
  background-color: white;
  :after {
    content: "";
    position: absolute;
    display: none;
  }
`

export const CheckRound = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  margin-top: -5px;
  width: 20px;
  border-radius: 10px;
  background-color: white;
  border: 0.5px solid lightgray;
`

export const CheckBoxContainer = styled.div`
  margin: 8.5px;
  margin-top: 20px;
  margin-left: 10px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  font-family: Poppins;
  -webkit-user-select: none;
  -moz-user-select: none;
  color: #66717f;
  -ms-user-select: none;
  user-select: none;

  :hover input ~ ${CheckMark} {
    background-color: #052957;
  }
  :hover input ~ ${CheckMark}:checked {
    background-color: red;
  }
  ${CheckMark}:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media ${device.tablet} {
    margin-left: 80px;
    margin-right: 0px;
  }

  @media ${device.phone} {
    margin-left: 40px;
    margin-right: 0px;
  }
`

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ ${CheckRound} {
    background-color: #337ab7;
  }
  :checked ~ ${CheckRound}:after {
    display: block;
  }
  :checked ~ ${CheckMark} {
    background-color: #337ab7;
  }
  :checked ~ ${CheckMark}:after {
    display: block;
  }
`
