import React from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"

const Container = styled.div`
  display: inline-block;
  align-items: center;
`

const Check = styled.input`
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-top: -5px;

  :checked {
    background-color: ${props => props.theme.blue};
  }
`

const Label = styled.label`
  font-weight: 500;
  margin-top: 10px;
  color: ${props => props.theme.darkblue};
  margin-left: 10px;

  @media ${device.tablet} {
    font-size: 12px;
  }
`

export default function GlobalCheckBox({ label, id, checked, onClick }) {
  return (
    <Container onClick={onClick}>
      <Check checked={checked} type="checkbox" id={id} />
      <Label for={id}>{label}</Label>
    </Container>
  )
}
