import React, { useEffect, useRef, useState } from "react"
import Menu from "../Menu/Menu"
import Burger from "../Burger/Burger"
import {
  Container,
  LogoLink,
  Images,
  Image,
  SearchBar,
  SearchImg,
  SearchWrapper,
  SearchBarWrapper,
  ProfileWrapper,
  ButtonOutline,
  ButtonLight,
  ProfileTitle,
  ButtonDark,
  Wrapper,
} from "./styles/MobileBar.styled"
import logo from "../../images/logo.png"

import { Autocomplete } from "@material-ui/lab"
import { navigate } from "gatsby"
import RegisterModalMobile from "../RegisterModalMobile/RegisterModalMobile"
import LoginModalMobile from "../LoginModalMobile/LoginModalMobile"
import PasswordForgotModalMobile from "../PasswordForgotModalMobile/PasswordForgotModalMobile"

import navigateToTrattamento from "../../functions/navigateToTrattamento"
import { useSelector, useDispatch } from "react-redux"
import {
  selectLayoutTrattamenti,
  selectLayoutChirurghi,
} from "../../redux/layoutSlice"
import navigateToChirurgo from "../../functions/navigateToChirurgo"
import { logoutSuccess, selectCurrentUser } from "../../redux/userSlice"

const MobileBar = () => {
  const [open, setOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [forgotModal, setForgotModal] = useState(false)
  const trattamenti = useSelector(selectLayoutTrattamenti)
  const chirurghi = useSelector(selectLayoutChirurghi)
  const parentRef = useRef(null)
  const [search, setSearch] = useState(null)
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!search) {
      return
    }
    if (search?.trattamento) {
      navigateToTrattamento(search)
    }
    if (search?.nome) {
      navigateToChirurgo(search)
    }
  }, [search])

  return (
    <Wrapper ref={parentRef}>
      <RegisterModalMobile
        openLogin={() => setLoginModal(true)}
        open={modal}
        setOpen={setModal}
      />
      <LoginModalMobile
        openRegister={() => setModal(true)}
        openForgot={() => setForgotModal(true)}
        open={loginModal}
        setOpen={setLoginModal}
      />
      <PasswordForgotModalMobile open={forgotModal} setOpen={setForgotModal} />
      <ProfileWrapper searchOpen={profileOpen}>
        {user?.user_email ? (
          <>
            <ButtonLight
              style={{ marginBottom: 10 }}
              onClick={() => {
                navigate("/profilo")
              }}
            >
              VAI AL PROFILO
            </ButtonLight>
            <ButtonOutline
              style={{ marginBottom: 0 }}
              onClick={() => {
                dispatch(logoutSuccess())
              }}
            >
              LOGOUT
            </ButtonOutline>
          </>
        ) : (
          <>
            <ButtonOutline
              onClick={() => {
                setModal(true)
              }}
            >
              REGISTRATI
            </ButtonOutline>
            <ButtonLight
              onClick={() => {
                setLoginModal(true)
              }}
            >
              ACCEDI
            </ButtonLight>
          </>
        )}
        <ProfileTitle>Chirurgo</ProfileTitle>
        <ButtonDark onClick={() => navigate("https://backend.chirurghiestetici.it/admin_chirurgo/admin_chirurgo_login.aspx")}>
          AREA CHIRURGO
        </ButtonDark>
      </ProfileWrapper>
      <SearchWrapper searchOpen={searchOpen}>
        {trattamenti && (
          <Autocomplete
            freeSolo
            value={search}
            onChange={(event, newValue) => {
              setSearch(newValue)
            }}
            options={[...trattamenti, ...chirurghi]}
            getOptionLabel={option => option.trattamento ?? option.nome}
            renderInput={params => (
              <SearchBarWrapper ref={params.InputProps.ref}>
                <SearchBar {...params.inputProps} placeholder="Ricerca" />
                <SearchImg src={require("../../images/Search.png")} />
              </SearchBarWrapper>
            )}
          />
        )}
      </SearchWrapper>
      <Container>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
        <LogoLink to={"/"}>
          <img height="50px" src={logo} />
        </LogoLink>
        <Images>
          <Image
            onClick={() => {
              setProfileOpen(false)
              setSearchOpen(!searchOpen)
            }}
            src={require("../../images/mobilebar/search.png")}
          />
          <Image
            onClick={() => {
              setSearchOpen(false)
              setProfileOpen(!profileOpen)
            }}
            src={require("../../images/mobilebar/User.png")}
          />
        </Images>
      </Container>
    </Wrapper>
  )
}

export default MobileBar
