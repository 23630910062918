import styled, { createGlobalStyle } from "styled-components"
import { device } from "./breakpoints"

export const GlobalRow = styled.div`
  display: flex !important;
  flex-direction: row;
  align-content: center;
  vertical-align: center;
  align-items: center;
`

export const TrattamentoItemContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 150px;
  background: #fafafa;
  @media ${device.tablet} {
    padding-top: 15px;
    flex-direction: column;
  }
`

export const SectionTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #082041;
  width: 100%;

  @media ${device.phone} {
    font-size: 22px;
  }
`

export const SectionSubTitle = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: -2px;
  width: 100%;
  text-align: center;
  color: #337ab7;
  @media ${device.phone} {
    font-size: 16px;
  }
`

export const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:400,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
html, body{
    box-sizing: border-box;
    margin:0;
    padding:0;
    font:system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}
`
