import { makeStyles } from "@material-ui/core"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"

export const useStyles = makeStyles(theme => ({
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: "translateZ(0)",
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export const Container = styled.div`
  min-width: 500px;

  @media ${device.tablet} {
    min-width: 300px;
  }
`

export const HeaderTitle = styled.b`
  font-size: 20px;
  margin: auto;
`
export const Wrapper = styled.div`
  max-width: 600px;
  max-height: 560px;
  background: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px !important;
  padding-bottom: 60px;
  @media ${device.tablet} {
    width: 100%;
  }
`

export const Inner = styled.div`
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  max-height: 500px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  transition: all 0.5s linear;
  align-items: center;
  flex-direction: column;
`

export const CloseBtn = styled.p`
  font-size: 24px;
  padding-right: 20px;
  cursor: pointer;
  @media ${device.tablet} {
    margin-top: -8px;
  }
`

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20;
  width: 220;
  justify-content: space-between;
`

export const Input = styled.input`
  background: white;
  text-align: center;
  border: none;
  box-shadow: 5px 5px 20px #efefef;
  width: 300px;
  -webkit-appearance: none;
  outline: none;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  font-size: 18px;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 20px;
  font-size: 14px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`
export const Header = styled.div`
  position: sticky;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  width: 100%;
  height: 60px;
  background: ${props => props.theme.darkblue};
  font-size: 28px;
  text-align: center;
  vertical-align: center;
  font-weight: 400;
  display: flex;
  color: white;
  @media ${device.tablet} {
    font-size: 18px;
  }
`

export const LinkNo = styled.span`
  color: ${props => (props.active ? props.theme.blue : props.theme.midblue)};
  font-size: 16px;
  font-weight: 600;
`

export const Link = styled(LinkNo)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.blue};
  }
  font-size: 16px;
`

export const LinksContainer = styled.div`
  margin: 20px;
`

export const XsP = styled.p`
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 15px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.blue};
  }
`
