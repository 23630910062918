import { navigate } from "gatsby"
import { GET_MENU } from "../constants/api"
import axios from "axios"
import formatTrattamento from "./formatTrattamento"
const navigateToTratt = async (i, extra) => {
  const menu = await axios.get(GET_MENU)
  const categoria = menu.data.Categorie.find(cat => cat.id_sys === i.id_cat)
  console.log({ categoria })
  navigate(
    `/${(i.macrocategoria || categoria.macrocategoria)
      .toLowerCase()
      .replace(" ", "-")}/${(i.categoria || categoria.categoria).toLowerCase()}/${(
      formatTrattamento(i.trattamento) || formatTrattamento(i.nome)
    ).replaceAll(" ", "-")}${extra ? extra : ""}`
  )
}

export default navigateToTratt
