import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import { colors } from "../../constants/Colors"

import styled from "styled-components"
import MobileFormInput, {
  MobileFormInputStyle,
} from "../MobileFormInputs/MobileFormInput"
import AuthIcons from "../Atoms/AuthIcons"
import CustomButton from "../Atoms/Button"
import { useForm } from "react-hook-form"
import { openErrorToast, openInfoToast } from "../../redux/toastSlice"
import { useDispatch } from "react-redux"
import { REGISTRAZIONE_POPUP } from "../../constants/popups"
import GlobalCheckBox from "../GlobalCheckBox/GlobalCheckBox"
import PrivacyNotice from "../Atoms/PrivacyNotice"
import { registerUserAsync } from "../../redux/userSlice"

const Footer = styled.div`
  font-weight: 600;
  margin-top: 50px;
  font-size: 14px;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
`

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    background: colors.BLUE,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))
const Inner = styled.div`
  margin: 30px;
  padding-bottom: 50px;
`
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function RegisterModalMobile({ open, setOpen, openLogin }) {
  const classes = useStyles()
  const { register, handleSubmit } = useForm()
  const [checked, setChecked] = React.useState(0)
  const dispatch = useDispatch()

  const onSubmit = data => {
    if (data.password != data.repassword) {
      dispatch(openErrorToast("Le password non corrispondono"))
      return
    }
    dispatch(
      registerUserAsync({ ...data, sex: checked == 0 ? "M" : "F" }, () =>
        dispatch(openInfoToast(REGISTRAZIONE_POPUP))
      )
    )
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const goToLogin = () => {
    handleClose()
    openLogin()
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Registrati
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Inner>
        <AuthIcons
          style={{ textAlign: "center" }}
          isLogin={false}
          close={handleClose}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <MobileFormInputStyle
            {...register("nome")}
            style={{ marginTop: 30 }}
            placeholder={"Nome"}
          />
          <MobileFormInputStyle
            {...register("cognome")}
            style={{ marginTop: 30 }}
            placeholder={"Cognome"}
          />
          <MobileFormInputStyle
            {...register("comune")}
            style={{ marginTop: 30 }}
            placeholder={"Città"}
          />
          <MobileFormInputStyle
            {...register("email")}
            type={"email"}
            style={{ marginTop: 30 }}
            placeholder={"Email"}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 10,
              marginTop: 15,
              marginBottom: 0,
            }}
          >
            <GlobalCheckBox
              onClick={() => setChecked(0)}
              checked={checked == 0}
              id={"check1"}
              label={"Uomo"}
            />
            <GlobalCheckBox
              onClick={() => setChecked(1)}
              checked={checked == 1}
              id={"check2"}
              label={"Donna"}
            />
          </div>
          <MobileFormInputStyle
            {...register("password")}
            style={{ marginTop: 30 }}
            type={"password"}
            placeholder={"Password"}
          />
          <MobileFormInputStyle
            {...register("repassword")}
            type={"password"}
            style={{ marginTop: 30 }}
            placeholder={"Ripeti password"}
          />
          <div style={{ textAlign: "center", marginTop: 30 }}>
            <CustomButton>Registrati</CustomButton>
            <PrivacyNotice />
          </div>
          <Footer onClick={() => goToLogin()}>
            Hai già un account?{" "}
            <span style={{ color: colors.BLUE }}>Accedi</span>
          </Footer>
        </form>
      </Inner>
    </Dialog>
  )
}
