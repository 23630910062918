export const MenuItems = [
  {
    title: "Chirurgia Estetica",
    href: "/chirurgia-estetica",
    subItems: [
      {
        title: "Viso",
        href: "#",
        subItems: [
          {
            title: "Blefaroplastica",
            href: "#",
          },
          {
            title: "Otoplastica",
            href: "#",
          },
          {
            title: "Mentoplastica",
            href: "#",
          },
          {
            title: "Lipofilling viso",
            href: "#",
          },
        ],
      },
      {
        title: "Naso",
        href: "#",
        subItems: [
          {
            title: "Blefaroplastica",
            href: "#",
          },
          {
            title: "Otoplastica",
            href: "#",
          },
          {
            title: "Mentoplastica",
            href: "#",
          },
          {
            title: "Lipofilling viso",
            href: "#",
          },
        ],
      },
      {
        title: "Seno",
        href: "#",
        subItems: [
          {
            title: "Blefaroplastica",
            href: "#",
          },
          {
            title: "Otoplastica",
            href: "#",
          },
          {
            title: "Mentoplastica",
            href: "#",
          },
          {
            title: "Lipofilling viso",
            href: "#",
          },
        ],
      },
      {
        title: "Corpo",
        href: "#",
        subItems: [
          {
            title: "Blefaroplastica",
            href: "#",
          },
          {
            title: "Otoplastica",
            href: "#",
          },
          {
            title: "Mentoplastica",
            href: "#",
          },
          {
            title: "Lipofilling viso",
            href: "#",
          },
        ],
      },
      {
        title: "Intimo",
        href: "#",
        subItems: [
          {
            title: "Blefaroplastica",
            href: "#",
          },
          {
            title: "Otoplastica",
            href: "#",
          },
          {
            title: "Mentoplastica",
            href: "#",
          },
          {
            title: "Lipofilling viso",
            href: "#",
          },
        ],
      },
      {
        title: "Capelli",
        href: "#",
        subItems: [
          {
            title: "Blefaroplastica",
            href: "#",
          },
          {
            title: "Otoplastica",
            href: "#",
          },
          {
            title: "Mentoplastica",
            href: "#",
          },
          {
            title: "Lipofilling viso",
            href: "#",
          },
        ],
      },
    ],
  },
  {
    title: "Medicina Estetica",
    href: "/medicina-estetica",
  },
  {
    title: "Chirurghi",
    href: "/chirurghi-estetici",
  },
  {
    title: "Esperienze",
    href: "/esperienze",
  },
  {
    title: "Articoli",
    href: "/articoli",
  },
]
