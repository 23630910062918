import { Modal, Slide } from "@material-ui/core"

import React, { useState } from "react"

import AuthIcons from "../Atoms/AuthIcons"
import CloseIconContainer from "../Atoms/CloseIcon"

import {
  useStyles,
  Wrapper,
  Container,
  Inner,
  HeaderTitle,
  Header,
} from "./AuthModal.styled"
import LoginForm from "./LoginForm"
import PasswordForgot from "./PasswordForgot"
import RegisterForm from "./RegisterForm"

export default function AuthModal({ parentRef, open, close, passedState }) {
  const [state, setState] = useState(passedState || 0)

  const classes = useStyles()
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      onClose={close}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      container={() => parentRef.current}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Wrapper>
          <Container>
            <Header>
              <CloseIconContainer
                style={{ marginRight: "auto", visibility: "hidden" }}
              />
              <HeaderTitle>
                {state == 0
                  ? "ACCEDI"
                  : state == 1
                  ? "REGISTRATI"
                  : "RECUPERA PASSWORD"}
              </HeaderTitle>
              <CloseIconContainer
                onClick={close}
                style={{ marginLeft: "auto", color: "white", marginTop: -5 }}
              />
            </Header>
            <Inner>
              {state == 0 ? (
                <LoginForm setState={setState} close={close} />
              ) : state == 1 ? (
                <RegisterForm setState={setState} close={close} />
              ) : (
                <PasswordForgot setState={setState} close={close} />
              )}
            </Inner>
          </Container>
        </Wrapper>
      </Slide>
    </Modal>
  )
}
