import { Link } from "gatsby"
import styled from "styled-components"

export const LinkCaret = styled.svg`
  fill: none;
  margin-left: 0.5em;
  path {
    stroke: currentColor;
  }
`

export const Container = styled.div`
  position: fixed;
  top: -10px;
  width: 100%;
  padding-top: 20px;
  display: flex;
  z-index: 10;
  flex-direction: row;
  background-color: white;
  box-shadow: 0px 3px 6px #00000012;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 20px;
  @media (max-width: 980px) {
    display: none;
  }
`

export const Item = styled(Link)`
  float: left;
  font-size: 16px;
  text-decoration: none;
  color: ${({ active }) => (active ? "#337ab7" : "#172d4a")};
  font-weight: 700;
  :hover {
    color: #337ab7;
  }
  text-align: center;
  padding: 14px 16px;
  @media (max-width: 1225px) {
    font-size: 12px !important;
  }
`

export const ItemDiv = styled(Link)`
  float: left;
  font-size: 16px;
  text-decoration: none;
  color: #172d4a;
  font-weight: 700;
  :hover {
    color: #337ab7;
  }
  text-align: center;
  padding: 14px 16px;
  @media (max-width: 1225px) {
    font-size: 12px !important;
  }
`

export const DropContentWrapper = styled.div`
  display: none;
  position: absolute;
  background-color: transparent;
  padding-top: 25px;
  width: 100%;
  left: 0;
`

export const DropContent = styled.div`
  background-color: #f9f9f9;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`

export const DropButton = styled(Link)`
  font-size: 16px;

  color: #172d4a;
  font-weight: 700;
  :hover {
    color: #337ab7;
  }
  padding: 14px 16px;
  background-color: inherit;
  align-self: center;
  text-decoration: none;
  margin-top: 20px;
  @media (max-width: 1225px) {
    font-size: 12px !important;
  }
`

export const Dropdown = styled.div`
  float: left;
  font-weight: 700;
  overflow: hidden;
  margin-top: 14px;
  z-index: 9999999;
  :hover ${DropContentWrapper} {
    display: block;
  }
  @media (max-width: 1225px) {
    margin-top: 10px;
  }
`

export const Header = styled.div`
  padding: 8px;
  padding-top: 14px;
  text-align: left;
  flex-direction: row;
  display: flex;
  color: #efefef;
`

export const HeaderSpace = styled.div`
  flex: 8;

  @media (max-width: 1480px) {
    flex: 4;
  }

  @media (max-width: 1200px) {
    flex: 1;
  }
  @media (max-width: 1024px) {
    flex: 0;
  }
`

export const HeaderContent = styled.div`
  border-left-color: #efefef;
  max-height: 250px;
  flex-wrap: wrap;
  border-left-width: 1px;
  border-left-style: solid;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  color: #172d4a;
  margin-bottom: 10px;
  flex: 2;
  :hover {
    color: #337ab7;
  }
  cursor: pointer;
`

export const HeaderItem = styled.div`
  text-decoration: none;
  color: #172d4a;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
  max-width: ${({ isLong }) => (isLong ? "100px" : undefined)};
  :hover {
    color: #337ab7;
  }
  @media (min-width: 1280px) {
    font-size: 14px;
  }
  cursor: pointer;
`

export const HeaderTitle = styled.div`
  padding-left: 25px;
  font-weight: 700 !important;
  color: #264a79;
  font-weight: 500;
  margin-bottom: 10px;
  flex: 2;
`

export const LogoLink = styled(Link)`
  margin-bottom: -10px;
  width: 250px;
  margin-left: 50px;
  cursor: pointer;
  &:hover > img {
    animation: pulse 0.5s;
  }
  @media (max-width: 1480px) {
    flex: 0.2;
  }

  @media (max-width: 1225px) {
    flex: 0.2;
  }
`
