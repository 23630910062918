import React from "react"
import { CheckBoxContainer, Input, CheckMark, CheckRound } from "./styles/Check"

const CheckBox = ({ children, ...restProps }) => {
  return <CheckBoxContainer {...restProps}>{children}</CheckBoxContainer>
}

CheckBox.Input = function CheckBoxInput({ children, ...restProps }) {
  return <Input {...restProps}>{children}</Input>
}

CheckBox.CheckMark = function CheckBoxCheckMark({ children, ...restProps }) {
  return <CheckMark {...restProps}>{children}</CheckMark>
}

CheckBox.CheckRound = function CheckBoxCheckRound({ children, ...restProps }) {
  return <CheckRound {...restProps}>{children}</CheckRound>
}

export default CheckBox
