import { IconButton } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import React from "react"

export default function CloseIconContainer({ ...restProps }) {
  return (
    <IconButton aria-label="close" {...restProps}>
      <Close />
    </IconButton>
  )
}
