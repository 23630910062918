import React from "react"
import styled from "styled-components"

export const Icon = styled.img`
  margin-right: 10px;
  height: 16px;
  width: auto;
  background-size: contain;
`

export default function GlobalIcon({ img, ...restProps }) {
  return <Icon {...restProps} src={img} />
}
