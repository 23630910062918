import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { PASSWORD_FORGOT_POPUP } from "../../constants/popups"
import { openInfoToast,openErrorToast } from "../../redux/toastSlice"
import { CustomButtonInput } from "../Atoms/Button"
import {
  Form,
  Inner,
  Input,
  Link,
  LinkNo,
  LinksContainer,
  XsP,
} from "./AuthModal.styled"
import axios from "axios"
import { PASSWORD_FORGOT } from "../../constants/api"

const PasswordForgot = ({ setState, close }) => {
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const onSubmit = async data => {
    try {
      await axios.post(PASSWORD_FORGOT, {
      email: data.email,

    });
      dispatch(openInfoToast(PASSWORD_FORGOT_POPUP))
    }catch(e){
      dispatch(openErrorToast("Oops.. si è verificato un errore"))
    }
    close()
  }

  return (
    <Inner>
      <LinksContainer>
        <Link onClick={() => setState(0)} active={true}>
          <FontAwesomeIcon
            style={{ fontSize: 11, marginLeft: 5, fontWeight: 100 }}
            icon={faArrowLeft}
          />
          <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>
            Torna a login
          </span>
        </Link>
      </LinksContainer>
      <label for="forgot">
        Inserisci la tua e-mail per recuperare la password
      </label>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="email"
          required
          id="forgot"
          type={"email"}
          {...register("email")}
        />
        <div style={{ height: 20 }} />
        <CustomButtonInput value="INVIA" type="submit" />
      </Form>
    </Inner>
  )
}

export default PasswordForgot
