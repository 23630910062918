import { navigate } from "gatsby"
import axios from "axios"
import { GET_MENU } from "../constants/api"

export const navigateToArticolo = async ({ articolo }) => {
  const menu = await axios.get(GET_MENU)

  const trattamento = menu.data.Trattamenti.find(
    tratt => tratt?.trattamento === articolo?.trattamento
  )

  const categoria = menu.data.Categorie.find(
    cat => cat?.id_sys === trattamento?.id_cat
  )

  const titolo = articolo.id_sys

  navigate(
    `/${(categoria?.macrocategoria || "generico").toLowerCase().replace(" ", "-")}/${
      categoria?.categoria || "generico"
    }/${(trattamento?.nome || trattamento?.trattamento || "articolo-generico")?.replaceAll(
      " ",
      "-"
    )}/articoli/${titolo}`.toLowerCase()
  )
}
