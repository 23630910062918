import { Autocomplete as MaterialAuto } from "@material-ui/lab"
import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Title = styled.h1`
  font-weight: 700;

  color: #052957;
  font-size: 35.3px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Wrapper = styled.form`
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: rgba(51, 122, 183, 0.11);
`

export const Row = styled.div`
  display: flex;
  alig-content: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const Input = styled.input`
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  margin: 8.5px;
  flex: 1;
  ::placeholder {
    color: #99a0a9;
  }
  border-width: 0px;

  @media ${device.tablet} {
    width: 80%;
    &[title~="noresize"] {
      width: auto;
    }
  }
`

export const InputError = styled(Input)`
  border-color: #da2222ba;
  border-width: 2px;
  margin: 6.5px;
  border-style: solid;
  ::placeholder {
    color: #da2222ba;
  }
`
export const AutoComplete = styled(MaterialAuto)`
  flex: 1.16;
  @media ${device.tablet} {
    width: 80%;
    margin-left: -20px;
    align-self: center;
  }
`

export const InputAuto = styled.input`
  border: none;
  outline: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #99a0a9;
  }
`

export const InputAutoWrapper = styled.div`
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  margin: 8.5px;
  display: flex;
  align-items: center;
  border-width: 0px;
  justify-content: space-between;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const InputAutoWrapperError = styled(InputAutoWrapper)`
  border-color: #da2222ba;
  border-width: 2px;
  border-style: solid;
  ::placeholder {
    color: #da2222ba;
  }
`

export const InputLarge = styled.textarea`
  border-radius: 12px;
  padding: 20px;
  padding-bottom: 100px;
  border-width: 0px;
  margin: 8.5px;

  @media ${device.tablet} {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
`

export const ButtonInput = styled.input`
  border-radius: 12px;
  padding: 17.5px;
  border: 0;
  width: 200px;
  background: #264a79;
  color: white;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 20px;
`

export const Button = styled.button`
  border-radius: 12px;
  padding: 17.5px;
  border: 0;
  width: 200px;
  background: #264a79;
  color: white;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 20px;
`
