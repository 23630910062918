import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { LOGIN_POPUP } from "../../constants/popups"
import { openInfoToast } from "../../redux/toastSlice"
import AuthIcons from "../Atoms/AuthIcons"
import { CustomButtonInput } from "../Atoms/Button"
import {
  Form,
  Inner,
  Input,
  Link,
  LinkNo,
  LinksContainer,
  XsP,
} from "./AuthModal.styled"
import { loginUserAsync } from "../../redux/userSlice"
import { navigate } from "gatsby-link"

const LoginForm = ({ setState, close }) => {
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const onSubmit = data => {
    close()
    dispatch(loginUserAsync(data, () => navigate("/profilo")))
  }

  return (
    <Inner>
      <LinksContainer>
        <Link onClick={() => setState(0)} active={true}>
          Accedi
        </Link>
        <LinkNo> / </LinkNo>
        <Link onClick={() => setState(1)} active={false}>
          Registrati
        </Link>
      </LinksContainer>
      <AuthIcons isLogin={true} close={close} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="email"
          required
          type={"email"}
          {...register("email")}
        />
        <Input
          placeholder="password"
          type={"password"}
          required
          {...register("password")}
        />
        <div style={{ height: 20 }} />
        <CustomButtonInput value="ACCEDI" type="submit" />
        <XsP onClick={() => setState(2)}>Ho dimenticato la password</XsP>
      </Form>
    </Inner>
  )
}

export default LoginForm
