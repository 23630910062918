export const breakpoints = {
  desktopL: 1440,
  desktop: 1280,
  laptop: 1024,
  tablet: 768,
  tabletSm: 600,
  phone: 375,
}

export const device = {
  desktopL: `(max-width: ${breakpoints.desktopL}px)`,
  desktop: `(max-width: ${breakpoints.desktop}px)`,
  laptop: `(max-width: ${breakpoints.laptop}px)`,
  tablet: `(max-width: ${breakpoints.tablet}px)`,
  tabletSm: `(max-width: ${breakpoints.tabletSm}px)`,
  phone: `(max-width: ${breakpoints.phone}px)`,
}
