import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import { colors } from "../../constants/Colors"

import styled from "styled-components"
import MobileFormInput, {
  MobileFormInputStyle,
} from "../MobileFormInputs/MobileFormInput"
import AuthIcons from "../Atoms/AuthIcons"
import CustomButton from "../Atoms/Button"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { LOGIN_POPUP, PASSWORD_FORGOT_POPUP } from "../../constants/popups"
import { openInfoToast } from "../../redux/toastSlice"

const Footer = styled.div`
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
`

const PasswordForgot = styled.div`
  color: #334154;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
`

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    background: colors.BLUE,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Inner = styled.div`
  margin: 30px;
  padding-bottom: 50px;
`
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function PasswordForgotModalMobile({ open, setOpen }) {
  const classes = useStyles()
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = data => {
    dispatch(openInfoToast(PASSWORD_FORGOT_POPUP))
    handleClose()
    alert(JSON.stringify(data))
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Recupera password
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Inner>
          <p style={{ textAlign: "center" }}>
            Inserisci il tuo indirizzo email
          </p>
          <MobileFormInputStyle
            required
            {...register("email")}
            style={{ marginTop: 30 }}
            placeholder={"Email"}
            type="email"
          />
          <div style={{ textAlign: "center", marginTop: 30 }}>
            <CustomButton>Invia</CustomButton>
          </div>
        </Inner>
      </form>
    </Dialog>
  )
}
