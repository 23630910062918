import React, { useEffect, useState } from "react"

import Footer from "../components/Footer/Footer"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchFooterAsync,
  selectFooterArticoli,
  selectFooterComuni,
  selectFooterLoading,
  selectFooterTrattamenti,
} from "../redux/footerSlice"
import { selectCurrentUser } from "../redux/userSlice"
import navigateToTrattamento from "../functions/navigateToTrattamento"
import { navigateToArticolo } from "../functions/navigateToArticoli"

export default function FooterContainer() {
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const trattamenti = useSelector(selectFooterTrattamenti)
  const articoli = useSelector(selectFooterArticoli)
  const cities = useSelector(selectFooterComuni)
  const dispatch = useDispatch()
  const loading = useSelector(selectFooterLoading)
  const user = useSelector(selectCurrentUser)
  const [position, setPosition] = useState()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      },
      e => console.log("ERROR", e)
    )
  }, [])

  useEffect(() => {
    dispatch(fetchFooterAsync(position?.lat, position?.long))
  }, [position?.lat, position?.long])

  if (loading) {
    return null
  }
  return (
    <Footer>
      <Footer.FeatureWrapper>
        <Footer.Feature>
          <Footer.FeatureText>Scarica la nostra App mobile</Footer.FeatureText>
          <Footer.FeatureImage href={"#"}>
            <img width="140px" src={require("../images/Appstore.png")} />
          </Footer.FeatureImage>
          <Footer.FeatureImage href={"#"}>
            <img width="150px" src={require("../images/Playstore.png")} />
          </Footer.FeatureImage>
        </Footer.Feature>
      </Footer.FeatureWrapper>
      <Footer.ContentContainer>
        <Footer.Wrapper>
          <Footer.Row>
            <Footer.Column>
              <Footer.Title>Chirurgia Estetica</Footer.Title>
              <Footer.ItemsContainer open={open1}>
                {trattamenti["ChirurgiaEstetica"].slice(0, 5).map(i => (
                  <Footer.Link
                    onClick={() => navigateToTrattamento(i)}
                    key={i.id_sys}
                  >
                    {i.trattamento}
                  </Footer.Link>
                ))}
              </Footer.ItemsContainer>
              <Footer.Title
                title={"dropdown city"}
                open={open2}
                onClick={() => setOpen2(!open2)}
              >
                Città
              </Footer.Title>
              <Footer.ItemsContainer open={open2}>
                {(cities || []).slice(0, 5).map(i => (
                  <Footer.Link
                    onClick={() =>
                      navigate(`/chirurghi-estetici/${i.comune.toLowerCase()}`, {
                        state: { comune: i },
                      })
                    }
                    key={i.id_sys}
                  >
                    {i.comune}
                  </Footer.Link>
                ))}
              </Footer.ItemsContainer>
            </Footer.Column>
            <Footer.Column title={"bottom"}>
              <Footer.Title>Medicina Estetica</Footer.Title>
              <Footer.ItemsContainer open={open3}>
                {trattamenti["MedicinaEstetica"].slice(0, 5).map(i => (
                  <Footer.Link
                    onClick={() => navigateToTrattamento(i)}
                    key={i.id_sys}
                  >
                    {i.trattamento}
                  </Footer.Link>
                ))}
              </Footer.ItemsContainer>
              <Footer.Title title={"articoli"}>Articoli recenti</Footer.Title>
              <Footer.ItemsContainer title={"articoli"}>
                {articoli.slice(0, 5).map(i => (
                  <Footer.Link
                    onClick={() => navigateToArticolo({ articolo: i })}
                    key={i.id_sys}
                  >
                    {i.short_title}
                  </Footer.Link>
                ))}
              </Footer.ItemsContainer>
            </Footer.Column>
            <Footer.Column>
              <Footer.Spacer />
              <Footer.ItemsContainer open={open3}>
                {trattamenti["MedicinaEstetica"].slice(5, 10).map(i => (
                  <Footer.Link
                    onClick={() => navigateToTrattamento(i)}
                    key={i.id_sys}
                  >
                    {i.trattamento}
                  </Footer.Link>
                ))}
              </Footer.ItemsContainer>
              <Footer.Spacer />
              <Footer.ItemsContainer open={open3}>
                {articoli.slice(5, 10).map(i => (
                  <Footer.Link
                    onClick={() => navigateToArticolo({ articolo: i })}
                    key={i.id_sys}
                  >
                    {i.short_title}
                  </Footer.Link>
                ))}
              </Footer.ItemsContainer>
            </Footer.Column>
            <Footer.Column>
              <Footer.Title>Chirurghi estetici </Footer.Title>
              <Footer.ItemsContainer title={"expanded"}>
                <Footer.Link to="/chi_siamo">Chi siamo</Footer.Link>
                <Footer.Link to="/perche_sceglierci">
                  Perchè sceglierci
                </Footer.Link>
              </Footer.ItemsContainer>
              {user?.user_email && (
                <>
                  <Footer.Title>Account</Footer.Title>
                  <Footer.ItemsContainer title={"expanded"}>
                    <Footer.Link to="/profilo">Profilo</Footer.Link>
                  </Footer.ItemsContainer>
                </>
              )}
              <Footer.Title>Area Chirurghi</Footer.Title>
              <Footer.ItemsContainer title={"expanded"}>
                <Footer.Link to="http://chirurghiestetici.netfood.cloud/Admin/admin_login.aspx">
                  Accedi/ Registrati
                </Footer.Link>
                <Footer.Link to="/cosa_offriamo">Cosa offriamo</Footer.Link>
              </Footer.ItemsContainer>
              <Footer.Title>Contatti</Footer.Title>
              <Footer.ItemsContainer title={"expanded"}>
                <Footer.Link href="#">info@chirurghiestetici.it</Footer.Link>
              </Footer.ItemsContainer>
            </Footer.Column>
          </Footer.Row>
          <Footer.Bottom>
            <Footer.BottomLinks>
              <Footer.BottomLink to="/">Privacy Policy</Footer.BottomLink>
              &nbsp;|&nbsp;
              <Footer.BottomLink to="/">Cookie</Footer.BottomLink>
              &nbsp;|&nbsp;
              <Footer.BottomLink to="/">Politica editoriale</Footer.BottomLink>
            </Footer.BottomLinks>
            <Footer.BottomIcons>
              <Footer.BottomIcon>
                <img width={"20px"} src={require("../images/facebook.png")} />
              </Footer.BottomIcon>
              <Footer.BottomIcon>
                <img width={"20px"} src={require("../images/instagram.png")} />
              </Footer.BottomIcon>
              <Footer.BottomIcon>
                <img width={"20px"} src={require("../images/twitter.png")} />
              </Footer.BottomIcon>
            </Footer.BottomIcons>
          </Footer.Bottom>
        </Footer.Wrapper>
      </Footer.ContentContainer>
    </Footer>
  )
}
