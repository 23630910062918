import React from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  ItemsContainer,
  Container,
  Wrapper,
  Row,
  Column,
  Link,
  Title,
  FeatureWrapper,
  Feature,
  ContentContainer,
  FeatureImage,
  FeatureText,
  Bottom,
  Spacer,
  BottomIcons,
  BottomIcon,
  BottomLink,
  BottomLinks,
} from "./styles/Foot"

const Footer = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

Footer.ContentContainer = function FooterContentContainer({
  children,
  ...restProps
}) {
  return <ContentContainer {...restProps}>{children}</ContentContainer>
}

Footer.FeatureWrapper = function FooterFeatureWrapper({
  children,
  ...restProps
}) {
  return <FeatureWrapper {...restProps}>{children}</FeatureWrapper>
}

Footer.Feature = function FooterFeature({ children, ...restProps }) {
  return <Feature {...restProps}>{children}</Feature>
}

Footer.FeatureText = function FooterFeatureText({ children, ...restProps }) {
  return <FeatureText {...restProps}>{children}</FeatureText>
}

Footer.FeatureImage = function FooterFeatureImage({ children, ...restProps }) {
  return <FeatureImage {...restProps}>{children}</FeatureImage>
}

Footer.Wrapper = function FooterWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

Footer.Row = function FooterRow({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>
}

Footer.Column = function FooterColumn({ children, ...restProps }) {
  return <Column {...restProps}>{children}</Column>
}

Footer.Title = function FooterTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

Footer.Spacer = function FooterSpacer({ children, ...restProps }) {
  return <Spacer {...restProps}>{children}</Spacer>
}

Footer.Link = function FooterLink({ children, to, ...restProps }) {
  return (
    <Link {...restProps}>
      <GatsbyLink style={{ textDecoration: "none", color: "inherit" }} to={to}>
        {children}
      </GatsbyLink>
    </Link>
  )
}

Footer.Bottom = function FooterBottom({ children, ...restProps }) {
  return <Bottom {...restProps}>{children}</Bottom>
}

Footer.ItemsContainer = function FooterItemsContainer({
  children,
  ...restProps
}) {
  return <ItemsContainer {...restProps}>{children}</ItemsContainer>
}

Footer.BottomLinks = function FooterBottomLinks({ children, ...restProps }) {
  return <BottomLinks {...restProps}>{children}</BottomLinks>
}

Footer.BottomLink = function FooterBottomLink({ children, ...restProps }) {
  return <BottomLink {...restProps}>{children}</BottomLink>
}

Footer.BottomIcons = function FooterBottomIcons({ children, ...restProps }) {
  return <BottomIcons {...restProps}>{children}</BottomIcons>
}

Footer.BottomIcon = function FooterBottomIcon({ children, ...restProps }) {
  return <BottomIcon {...restProps}>{children}</BottomIcon>
}

export default Footer
