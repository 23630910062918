import { CircularProgress } from "@material-ui/core"
import React from "react"

import {
  Title,
  Wrapper,
  Container,
  Row,
  InputLarge,
  Input,
  Button,
  AcceptInput,
  ButtonInput,
  AcceptText,
  InputError,
  InputAutoWrapper,
  InputAutoWrapperError,
  InputAuto,
  AutoComplete,
} from "./styles/frm"

const ContactForm = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

ContactForm.Wrapper = function ContactFormWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

ContactForm.InputAuto = function HeaderSearchLeft(props) {
  if (props.error) {
    return (
      <InputAutoWrapperError ref={props.InputProps.ref}>
        <InputAuto
          placeholder={props.label}
          type="text"
          {...props.inputProps}
        />
        <svg
          height="6"
          role="img"
          viewBox="0 0 10 6"
          width="10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L5.07223 5.1517L9.23083 1"></path>
        </svg>
      </InputAutoWrapperError>
    )
  }
  return (
    <InputAutoWrapper ref={props.InputProps.ref}>
      <InputAuto placeholder={props.label} type="text" {...props.inputProps} />
      <svg
        height="6"
        role="img"
        viewBox="0 0 10 6"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L5.07223 5.1517L9.23083 1"></path>
      </svg>
    </InputAutoWrapper>
  )
}

ContactForm.Title = function ContactFormTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

ContactForm.Row = function ContactFormRow({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>
}

ContactForm.Input = function ContactFormInput({
  children,
  error,
  errorText,
  ...restProps
}) {
  console.log(restProps)
  return error ? (
    <InputError {...restProps}>{children}</InputError>
  ) : (
    <Input {...restProps}>{children}</Input>
  )
}

ContactForm.InputLarge = function ContactFormInputLarge({
  children,
  ...restProps
}) {
  return <InputLarge {...restProps}>{children}</InputLarge>
}

ContactForm.AutoComplete = function ContactFormAutoComplete({
  children,
  ...restProps
}) {
  return <AutoComplete {...restProps}>{children}</AutoComplete>
}

ContactForm.AcceptInput = function ContactFormAccept({
  children,
  ...restProps
}) {
  return <AcceptInput {...restProps}>{children}</AcceptInput>
}

ContactForm.AcceptText = function ContactFormAcceptText({
  children,
  ...restProps
}) {
  return <AcceptText {...restProps}>{children}</AcceptText>
}

ContactForm.Button = function ContactFormButton({
  children,
  loading,
  ...restProps
}) {
  return loading ? (
    <Button {...restProps}>
      {loading ? <CircularProgress size={22} color={"white"} /> : children}
    </Button>
  ) : (
    <ButtonInput {...restProps} />
  )
}

export default ContactForm
