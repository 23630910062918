import React from "react"
import styled from "styled-components"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import GlobalIcon from "./GlobalIcon"
import GoogleLogin, { useGoogleLogin } from "react-google-login"
import axios from "axios"
import { useDispatch } from "react-redux"
import { loginUserAsync, registerUserAsync } from "../../redux/userSlice"
const Container = styled.div`
  flex-direction: column;
`

const GlobalRow = styled.div`
  display: flex !important;
  flex-direction: row;
  align-content: center;
  vertical-align: center;
  align-items: center;
  justify-content: "space-between";
  margin-top: 20;
  width: 200;
  margin: "auto";
  margin-bottom: 20;
`
const SmallP = styled.p`
  font-weight: 300;
  font-size: 14px;
  margin-top: 30px;
`
export default function AuthIcons({ isLogin, close, ...restProps }) {
  const dispatch = useDispatch()

  const facebookAuth = res => {
    const token = res.accessToken
    axios
      .get("https://graph.facebook.com/me?fields=id,name,picture,email", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        const profile = res.data
        console.log(res.data)
        if (isLogin) {
          dispatch(
            loginUserAsync(
              {
                email: profile.email,
                password: profile.id,
              },
              () => close && close()
            )
          )
        } else {
          registerUserAsync(
            {
              email: profile.email,
              nome: profile.name,
              password: profile.id,
              nickname: profile.name,
              image_profilo: profile.picture.data.url,
              città: "",
              sesso: "M",
              federato: 1,
            },
            () => close && close()
          )
        }
      })
  }

  const onGoogleAuth = async res => {
    const token = res.accessToken
    await axios
      .get("https://www.googleapis.com/userinfo/v2/me", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        const profile = res.data
        console.log(res.data)
        if (isLogin) {
          dispatch(
            loginUserAsync(
              {
                email: profile.email,
                password: profile.id,
              },
              () => close && close()
            )
          )
        } else {
          dispatch(
            registerUserAsync(
              {
                email: profile.email,
                password: profile.id,
                nickname: profile.given_name,
                nome: profile.given_name,
                image_profilo: profile.picture,
                città: "",
                sesso: "M",
                federato: 2,
              },
              () => close && close()
            )
          )
        }
      })
  }
  const { signIn, loaded } = useGoogleLogin({
    onSuccess: onGoogleAuth,
    clientId:
      "452081151324-meqmrkmrit55sm8vbg21fdvuube1reeo.apps.googleusercontent.com",
  })

  return (
    <Container {...restProps}>
      {" "}
      <SmallP>Continua con</SmallP>
      <GlobalRow
        style={{
          justifyContent: "space-evenly",
          marginTop: 20,
          width: 200,
          margin: "auto",
          marginBottom: 20,
        }}
      >
        <FacebookLogin
          appId="290630145947300"
          callback={facebookAuth}
          render={renderProps => (
            <GlobalIcon
              onClick={renderProps.onClick}
              style={{ height: 30 }}
              img={require("../../images/facebook_blue.png")}
            />
          )}
        />
        <GlobalIcon
          style={{ height: 30 }}
          onClick={signIn}
          img={require("../../images/google-color.png")}
        />
      </GlobalRow>
    </Container>
  )
}
