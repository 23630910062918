import React from "react"
import styled from "styled-components"

export const ButtonInput = styled.input`
  background: white;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-width: 0.1em;
  border-style: solid;
  max-width: 150px;
  font-size: 14px;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
  border: 0;
  font-weight: 600;
  min-width: 150px;
  background: ${props => props.color};
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.8;
  }
`

export const ButtonS = styled.button`
  background: white;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-width: 0.1em;
  border-style: solid;
  font-size: 14px;
  margin-right: 5px;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
  border: 0;
  font-weight: 600;
  min-width: 150px;
  background: ${props => props.color};
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.8;
  }
`

export default function CustomButton({
  color = "#337ab7",
  children,
  ...restProps
}) {
  return (
    <ButtonS {...restProps} color={color}>
      {children}
    </ButtonS>
  )
}

export function CustomButtonInput({
  color = "#337ab7",
  children,
  ...restProps
}) {
  return <ButtonInput {...restProps} color={color} />
}
